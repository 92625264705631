import { cn } from "@ui/utils";

type BlurBackgroundProps = {
  className?: string;
};

export function BlurBackground({ className }: BlurBackgroundProps) {
  return (
    <div className={cn("-z-0 absolute inset-0", className)}>
      <div aria-hidden="true" className="-space-x-52 grid grid-cols-2 opacity-50 dark:opacity-30">
        <div className="h-60 bg-gradient-to-br from-primary to-indigo-400 blur-[106px] dark:from-blue-700" />
        <div className="h-40 bg-gradient-to-r from-cyan-400 to-sky-300 blur-[106px] dark:to-indigo-600" />
      </div>
    </div>
  );
}
