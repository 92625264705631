import { Link } from "@remix-run/react";
import { cn } from "@ui/utils";

const panelTypes = {
  white: {
    border: "border-l-white",
    bg: "bg-white",
  },
  primary: {
    border: "border-l-primary",
    bg: "bg-white",
  },
  secondary: {
    border: "border-l-secondary",
    bg: "bg-white",
  },
  disabled: {
    border: "border-l-secondary-disabled",
    bg: "bg-background-secondary",
  },
} as const;

type panelType = keyof typeof panelTypes;
type PanelFrameProps = {
  children: React.ReactNode;
  className?: string;
  type?: panelType;
  to?: string;
};

export function PanelFrame({ children, className, to, type = "white" }: PanelFrameProps) {
  const panelType = panelTypes[type];
  const clx = cn(
    "panel-frame space-y-8 rounded-3xl border border-gray-100 bg-white p-8 py-12 shadow-2xl shadow-gray-600/10 sm:p-12 dark:border-gray-700 dark:bg-gray-800 dark:shadow-none",
    panelType.bg,
    panelType.border,
    className
  );
  return to ? (
    <Link to={to} className={clx}>
      {children}
    </Link>
  ) : (
    <div className={clx}>{children}</div>
  );
}
